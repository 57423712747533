.sales-section{

    &__box{
        display: flex;
        flex-direction: column;
        grid-gap: clamp(50px, 5.102vw, 70px);
    }

    &__inner{
        position: relative;

        .main-btn{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
            min-width: 366px;

            @include for-small-tablet{
                bottom: clamp(10px, 2.6968vw, 37px);
            }

            @include small-tablet{
                bottom: 0;
            }

            @include ios{
                min-width: 100%;
            }
        }
    }

    &__bg{
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1512' height='1010' fill='none'%3E%3Cpath fill='url(%23paint0_linear_76_653)' d='M0 0h1512v1010H0z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_76_653' x1='756' x2='756' y1='0' y2='1010' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23EFF7FC' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23EFF7FC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        background-position: bottom;
        background-size: cover;
        background-repeat: repeat-x;
        height: 90%;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 2;
        pointer-events: none;
    }

    &__title{

        .main-label{
            
            &__text{
                text-transform: none;
                white-space: normal;
                line-height: 110%;
                padding: 7px 32px;
                border-radius: clamp(40px, 4.3732vw, 60px);
            }

            @include for-tablet{
                margin-left: 20px;
                top: 50%;
                transform: translateY(-90%);
                min-width: clamp(360px, 47.8863vw, 657px);
            }

            @include tablet{
                min-height: var(--label-height);
                top: 10px;
                min-width: clamp(320px, 47.8863vw, 657px);
            }
        }

        @include tablet{
            display: inline-flex;
            flex-direction: column;
        }
    }
}