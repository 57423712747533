.about-section{
    padding: 0 0 150px;
    position: relative;



    &__box{
        display: flex;
        flex-direction: column;
        grid-gap: clamp(25px, 5.102vw, 70px);
    }

    &__title{
        width: fit-content;
        position: relative;

        &-icon{
            display: block;
            width: clamp(357px, 35.7872vw, 491px);

            svg{
                width: 100%;
                height: auto;
            }

            @include for-small-tablet{
                position: absolute;
                bottom: 0;
                left: 65%;
                transform: translateY(28%);
            }

            @include small-tablet{
                transform: translateY(-10%);
            }
        }
    }
}