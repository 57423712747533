@keyframes rotate360 {
	from {
	  transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
	  transform: translate(-50%, -50%) rotate(360deg);
	}
  }

.loader {
	position: relative;

	&.loaded {
		&:before,
		&:after {
			opacity: 0;
		}
	}

	&:before,
	&:after {
		opacity: 1;
		content: '';
		position: absolute;
		display: flex;
		pointer-events: none;
		transition: all .3s ease;
		z-index: 20;
	}

	&:before {
		inset: -15px -5px;
		background-color: rgba(var(--bg-a),.7);
		backdrop-filter: blur(1px);
	}

	&:after {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 60%;
		max-height: 150px;
		aspect-ratio: 1;
		border-radius: 50%;
		border: 15px solid rgba(var(--bg-d),1.0);
		border-top-color: transparent;
		animation: rotate360 1.5s ease-in-out infinite;
		z-index: 21;
		transition-property: transform;
	}
}