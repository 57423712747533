.quiz-section{
    position: relative;
    z-index: 1;
    padding: clamp(93px, 7.5277vw, 102px) 0;
    flex-grow: 1;
    display: flex;

    &__bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        pointer-events: none;

        img,picture{
            width: 100%;
            height: 100%;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        justify-content: center;
    }

    &__box{
        display: flex;
        flex-direction: column;
        grid-gap: clamp(33px, 3.0996vw, 42px);
        position: relative;
        box-shadow: 10px -16px 34px 0 rgba(0, 0, 0, 0.25);
        background: rgba(var(--bg-a),1.0);
        border-radius: 40px;
        padding: 23px clamp(19px, 2.214vw, 30px) clamp(32px, 2.7306vw, 37px) clamp(19px, 2.583vw, 35px);
        max-width: 1019px;
        width: 100%;
        overflow: hidden;
    }

    &__bottom{
        display: flex;
        justify-content: space-between;
        grid-gap: 20px;
        align-self: center;
        width: 100%;

        .main-btn{
            min-width: clamp(149px, 20.5166vw, 278px);
            font-size: clamp(18px, 1.845vw, 25px);
            height: clamp(40px, 4.8708vw, 66px);
        }
    }

    &__info{
        font-family: var(--second-family);
        font-weight: 900;
        font-size: 30px;
        line-height: 150%;
        text-transform: uppercase;
        color: rgba(var(--cl-e),1.0);
        z-index: 25;

        @include for-mobile{
            position: absolute;
            right: 35px;
            top: 23px;
        }
    }

    &__all, &__current{
        font-style: normal;
    }

    .quiz-list{
        @include mobile{
            margin-top: calc(clamp(33px, 3.0996vw, 42px) * -1);
        }
    }
}