.select {
  position: relative;
  user-select: none;
  width: 100%;
  border: solid 2px rgba(11, 39, 49, 0.2);
  padding: 19.5px 26px;
  border-radius: 22px;
  transition: all .1s;

  &__header {
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    gap: 15px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 6;

    @include mobile {
      padding-right: 0;
    }

    & > svg {
      transition: transform .4s linear;
      flex-shrink: 0;

      width: 16px;
      aspect-ratio: 1;

      @include mobile {
        width: 14px;
      }
    }
  }

  &__current {
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: rgba(var(--cl-c),.43);
    font-size: 25px;
    font-family: var(--font-family);
    line-height: 100%;
    transition: all .3s;

    &:hover {
      color: rgba(var(--cl-c),1);
    }


    &-icon{
        width: 16px;
        height: 8px;
        fill: none;
    }
  }

  &__body {
    right: -2px;
    position: absolute;
    z-index: 5;
    height: 0;
    top: calc(100% + 1px);
    overflow: hidden;
    transition: height 0.1s ease-in;
    border-radius: 22px;
    left: -2px;
   
    i {
      margin: 0;
      text-transform: uppercase;
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__list {
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
    background: #FFF;
    padding-bottom: 14px;

    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 8px;
    }
     
  }

  &__option {
    display: flex;
    padding: 10px 26px 10px 26px;
    align-items: center;
    grid-gap: 5px;
    width: 100%;
    cursor: pointer;

    color: rgba(var(--cl-c),.43);
    font-size: 25px;
    font-family: var(--font-family);
    line-height: 100%;
    transition: all .3s;

    & + & {
      padding-top: 10px;
    }

    &:hover {
      color: rgba(var(--cl-c),1);
    }

    a {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    &[data-id='2']{
      padding-top: 0;
    }

  }
}

// .select.active .select__body {
//   height: auto;
// }

.select.active {
  z-index: 9;
  border-radius: 22px 22px 0 0;
  border-bottom-color: transparent;

  .select__body {
    border-bottom: solid 2px rgba(11, 39, 49, 0.2);
    border-left: solid 2px rgba(11, 39, 49, 0.2);
    border-right: solid 2px rgba(11, 39, 49, 0.2);
  
    border-radius: 0 0 22px 22px;
   
  }

  .select__header > svg {
    transform: scaleY(-1);
  }
}

//.default-select {
//  border-radius: 44px;
//  border: 1px solid var(--br-d);
//  background: rgba(var(--bg-a),1.0);
//
//  .select__current[data-id="0"] {
//    color: rgba(var(--cl-e),1.0);
//  }
//
//  &.active {
//    border-color: var(--br-b);
//  }
// }
