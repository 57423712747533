.first-section {
  position: relative;
  z-index: 5;

  &__bg {
    position: absolute;
    z-index: -1;
    pointer-events: none;
    inset: 0;

    img,
    picture {
      width: 100%;
      height: 100%;
      object-position: top center;
      overflow: hidden;
    }
  }

  &__box {
    position: relative;
    color: rgba(var(--cl-a), 1);
    display: flex;
    flex-direction: column;
    grid-gap: clamp(16px, 1.8222vw, 25px);

    @include for-mobile{
      padding: clamp(117px, 18.2216vw, 250px) 0 228px;
    }

    @include mobile{
      padding: clamp(117px, 18.2216vw, 250px) 0 234px;
    }

    .text{
      font-size: clamp(20px, 1.8222vw, 25px);

      @include mobile{
        font-weight: 400;
      }
    }
  }

  &__title {
    max-width: clamp(432px, 60.9329vw, 836px);
  }

  &__text {
    display: block;
    max-width: clamp(395px, 55.8309vw, 766px);
    margin-bottom: clamp(14px, 1.8222vw, 25px);
  }

  &__btn {
    @include for-mobile{
      padding: 0 50px;
    }

    @include mobile{
      width: 100%;
      padding: 0 10px;
    }
  }


  &--villas{
    margin-bottom: clamp(90px, 9.4752vw, 130px);

    .first-section{

      &__bg{
        img,picture{
          object-position: bottom;
        }
      }

      &__box{
        padding: clamp(148px, 16.1808vw, 222px) 0 101px;

        @include mobile{
          padding-bottom: 287px;
        }
      }

      &__title{
        color: rgba(var(--cl-a),1.0);
        max-width: clamp(359px, 46.3557vw, 636px);
        line-height: 123%;
      }
    }
  }
}
