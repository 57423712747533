.thank-section{
    padding: clamp(123px, 11.9557vw, 162px) 0 clamp(125px, 11.3653vw, 154px);

    &__box{
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: clamp(10px, 1.476vw, 20px);

        .main-btn{
            @include for-mobile{
                margin-top: 10px;
            }
        }
    }

    p{
        text-align: center;
        font-weight: 500;
        font-size: 25px;
    }

    &__title{
        text-align: center;
    }
}