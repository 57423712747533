.contacts {
  a {
    display: flex;
    gap: 11px;

    svg{
      position: relative;
      top: 3px;
    }
  }
}
