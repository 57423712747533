h1,.h1 {
  font-family: var(--second-family);
  font-size: clamp(30px, 4.0816vw, 56px);
  line-height: 114%;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

h2, .h2 {
  font-family: var(--second-family);
  font-size: clamp(30px, 4.519vw, 62px);
  line-height: 114%;
  font-weight: 900;
  color: rgba(var(--cl-d),1.0);
  text-transform: uppercase;
}

h3, .h3 {
  font-size: clamp(20px, 4.734375vw, 28px);
  font-weight: 700;
}

h4, .h4 {
  font-size: clamp(18px, 2.813vw, 20px);
  font-weight: 600;
}

h5, .h5 {
  font-weight: 700;
  font-size: clamp(32px, 3.6036vw, 40px);
}

.upper-text{
  text-transform: uppercase;
}

.subtitle{
  font-family: var(--second-family);
  font-weight: 900;
  font-size: clamp(30px, 3.6443vw, 50px);
  line-height: 130%;
  text-transform: uppercase;
}

.title{
  font-family: var(--second-family);
  font-weight: 900;
  font-size: 30px;
  line-height: 130%;
  text-transform: uppercase;
}

.white-text{
  color: rgba(var(--cl-a),1.0);
}

.logo img{
  width: 100%;
  height: 100%;
}


ol:not([class]){
  display: flex;
  flex-direction: column;
  li{
    list-style: disc;
  }

  @include laptop{
    padding-left: var(--container-offset);
  }
}

.fancybox__infobar{
  *{
    color: rgba(var(--cl-a),1.0);
  }
}

[data-fancybox]{
    outline: none;
}


// new
.text {
  font-size: 25px;
  line-height: 114%;
  font-weight: 500;
}

.main-label{
    display: inline-block;
    position: relative;
    // margin-left: 10px;
    min-width: 180px;
    min-height: 47px;
  
    &__text{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(-2deg);
    display: block;
    white-space: nowrap;
    background: rgba(var(--bg-d), 1);
    border-radius: 60px;
    font-weight: 600;
    font-size: 25px;
    padding: 7px 19px;
    width: fit-content;
    font-family: var(--font-family);
    color: rgba(var(--cl-b),1.0);
  }
}
