.sales-list{
    display: grid;
    grid-gap: 30px 21px;

    @include for-small-tablet{
        grid-template-columns: repeat(2, 1fr);
    }

    @include small-tablet{
        grid-template-columns: 100%;
    }

    &__item{
        @include mobile{
            &:nth-child(n+3){
                display: none;
            }
        }
    }
}