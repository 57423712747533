.main-nav{
    position: absolute;
    bottom: 0;
   
    z-index: 5;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    background: rgba(var(--bg-a),1.0);
    border-radius: clamp(40px, 5.102vw, 70px);
    padding: clamp(23px, 2.4781vw, 34px)  clamp(21px, 3.4257vw, 47px);
    width: 100%;

    @media (min-width: (1400px)) {
        --gap:Min(6.95vw, 105px);
    }

    @media (min-width: (1350px)) and (max-width: (1400px)) {
        --gap:Min(6.071vw, 85px);
    }

    @media (min-width: (1300px)) and (max-width: (1350px)) {
        --gap:Min(5.185vw, 70px);
    }

    @media (min-width: (1175px)) and (max-width: (1300px)) {
        --gap:Min(4.615vw, 60px);
    }

    @media (min-width: (1110px)) and (max-width: (1175px)) {
        --gap:Min(3.404vw, 40px);
    }

    @media (min-width: (1024px)) and (max-width: (1110px)) {
        --gap:Min(1.818vw, 20px);
    }

    
    @include for-laptop{
        padding: clamp(23px, 2.4781vw, 34px)  clamp(21px, 3.4257vw, 47px);
    }

    @include laptop{
        padding: clamp(23px, 2.4781vw, 34px)  clamp(21px, 1.8222vw, 25px);
    }

    @include for-tablet{
        right: 0;
        transform: translateY(50%);
        left: 0;
        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-gap: var(--gap);
    }

    @include tablet{
        grid-gap: 18px 34px;
        left: 50%;
        transform: translate(-50%, 50%);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: fit-content;
    }

    @include mobile{
        transform: translate(-50%, 75%);
    }

    &__icon{
        width: clamp(40px, 5.0292vw, 69px);
        height: clamp(40px, 5.0292vw, 69px);
        flex-shrink: 0;
    }

    &__item{
        display: flex;
        position: relative;

        @include for-tablet{
            width: fit-content;
        }

        &:first-child, &:nth-child(3){
            @include tablet{
            &::before{
                position: absolute;
                content: '';
                height: 26px;
                width: 1px;
                border-right: solid 1px var(--br-b);
                top: 50%;
                right: -17px;
                transform: translate(50%, -50%);
                pointer-events: none;
            }
        }
        }

        &:nth-child(3){
            @include tablet{
                .main-nav__link{
                justify-content: end;
            }
        }
        }

        &:not(:last-child){
            @include for-tablet{
                &::after{
                        position: absolute;
                        content: '';
                        height: clamp(50px, 5.143vw, 72px);
                        width: 1px;
                        border-right: solid 1px var(--br-b);
                        top: 50%;
                        right: calc((var(--gap) / 2) * -1);
                        transform: translate(50%, -50%);
                        pointer-events: none;
                }
            }
        }
    }

    &__link{
        display: flex;
        align-items: center;
        grid-gap: clamp(10px, 1.0933vw, 15px);
        font-weight: 500;
        font-size: 25px;
        text-align: center;
        color: rgba(var(--cl-c),1.0);
        

        @include for-tablet{
            white-space: nowrap;
        }
        
        &-text{

            &:not(.main-nav__link-text--mode){
                @include tablet{
                    display: none;
                }
            }

            &--mode{
                @include for-tablet{
                    display: none;
                }
            }
        }
    }
}