.quiz-list{


    &__item{
        display: flex;
        
        &:not(:first-of-type){
            display: none;
        }


        min-height: 220px;
    }
}