.about-card{
    display: flex;
    align-items: center;

    @include for-tablet{
        grid-gap: 25px clamp(25px, 4.2274vw, 58px);
    }

    @include tablet{
        grid-gap: 25px clamp(15px, 2.441vw, 25px);
    }

    @include small-tablet{
        flex-direction: column;
    }

    &__image{
        flex-shrink: 0;
        @include laptop{
        @include for-small-tablet{
            max-width: 60%;
        }
        }

        @include small-tablet{
            width: 100%;
            
            img,picture{
                border-radius: 20px;
                width: 100%;
                height: 100%;
                max-height: clamp(150px, 41.667vw, 320px);
            }
        }
    }

    &__box{
        grid-gap: clamp(10px, 1.4577vw, 20px);
        display: flex;
        flex-direction: column;
    }

    &__title{
        font-family: var(--second-family);
        font-weight: 900;
        font-size:clamp(21px, 2.1866vw, 30px);
        line-height: 114%;
        text-transform: uppercase;
    }

    p{
        font-size: clamp(16px, 1.4577vw, 20px);
    }
}