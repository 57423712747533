.images-list {
  display: flex;
  flex-direction: column;
  grid-gap: 21px;

  &__row {
    display: flex;
    flex-direction: row-reverse;
    grid-gap: 22px;

    &--mode {
      align-items: flex-end;

      .images-list__icon {
        width: clamp(176px, 15.7434vw, 216px);
        height: clamp(176px, 15.7434vw, 216px);

        &--small {
          width: clamp(72px, 7.5073vw, 103px);
          height: clamp(73px, 7.4344vw, 102px);
        }

        &--medium {
          width: clamp(104px, 10.6414vw, 146px);
          height: clamp(105px, 10.6414vw, 146px);
        }

        &--mode {
          width: clamp(126px, 12.828vw, 176px);
          height: clamp(126px, 12.828vw, 176px);
        }
      }
    }
  }

  &__icon {
    display: flex;
    width: clamp(201px, 17.5656vw, 241px);
    height: clamp(201px, 17.5656vw, 241px);
    flex-shrink: 0;

    img,
    picture {
      width: 100%;
      height: 100%;
    }

    &--small {
      width: clamp(122px, 11.0787vw, 152px);
      height: clamp(120px, 10.9329vw, 150px);
    }

    &--medium {
      width: clamp(166px, 15.0146vw, 206px);
      height: clamp(164px, 14.8688vw, 204px);
    }

    &--mode {
      width: clamp(126px, 12.828vw, 176px);
      height: clamp(126px, 12.828vw, 176px);
    }
  }

  &--mode {
    .images-list {
      &__row {
        flex-direction: row;

        &--mode {
          align-items: flex-end;

          .images-list__icon {
            width: clamp(185px, 20.1166vw, 276px);
            height: clamp(185px, 20.1166vw, 276px);

            &--medium {
              width: clamp(128px, 15.9621vw, 219px);
              height: clamp(128px, 15.9621vw, 219px);
              margin: 0;
            }

            &--mode {
              width: clamp(91px, 11.4431vw, 157px);
              height: clamp(91px, 11.4431vw, 157px);
            }

            &--small {
              width: clamp(64px, 8.0175vw, 110px);
              height: clamp(64px, 7.9446vw, 109px);
            }
          }
        }
      }

      &__icon {
        display: flex;
        width: clamp(197px, 20.9913vw, 288px);
        height: clamp(197px, 20.9913vw, 288px);
        flex-shrink: 0;

        img,
        picture {
          width: 100%;
          height: 100%;
        }
        &--medium {
          width: clamp(128px, 15.9621vw, 219px);
          height: clamp(128px, 15.9621vw, 219px);
          margin-left: 35px;
        }
      }
    }
  }
}
