.quiz{
    display: flex;
    flex-direction: column;

    @include for-mobile{
        grid-gap: clamp(15px, 2.952vw, 40px);
    }

    @include mobile{
        grid-gap: 15px;
    }
    
    &__box{
        display: flex;
        flex-direction: column;
        grid-gap: clamp(15px, 1.476vw, 20px);

        &--mode{
            grid-gap: clamp(34px, 2.583vw, 35px);

            .quiz__subtitle{
                @include for-small-tablet{
                    max-width: Min(46.6421vw, 632px);
                }
            }
        }
    }

    &__title{
        &--mode{
            @include small-tablet{
                @include for-mobile{
                    max-width: 80%;
                }
            }
        }
    }

    &__list{
        display: flex;
        grid-gap: clamp(15px, 1.1808vw, 16px);
        flex-direction: column;

        &--mode{
            display: grid;
            
            @include for-small-tablet{
                grid-template-columns: repeat(4, 1fr);
            }

            @include small-tablet{
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    &__top{
        z-index: 25;
        display: flex;
        flex-direction: column;
        grid-gap: 24px;
    }

    &__subtitle{
        font-size: clamp(18px, 1.845vw, 25px);

        @include for-mobile{
            font-weight: 500;
        }
        @include mobile{
            font-weight: 500;
        }
    }

    .main-form{

        &__label{
            &:last-child{
                grid-column: initial;
            }
        }
    }
}
