.quiz-checkbox {
  position: relative;

  input {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
  }

  span {
    cursor: pointer;
    position: relative;
    z-index: 2;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    user-select: none;
    color: rgba(var(--cl-b),1.0);
    width: 100%;
    font-family: var(--font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400; 
    line-height: 100%;

    &::before {
      cursor: pointer;
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      flex-grow: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%232C75BB'/%3E%3C/svg%3E");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  input {
    &:checked + span::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%232C75BB'/%3E%3Ccircle cx='9' cy='9' r='4' fill='%232C75BB'/%3E%3C/svg%3E");
    }
  }
}


.custom-checkbox {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  cursor: pointer;

  input {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
  }

  span {
    cursor: pointer;
    position: relative;
    z-index: 2;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    user-select: none;
    color: rgba(var(--cl-b),1.0);
    width: 100%;
    font-family: var(--font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400; 
    line-height: 100%;
  }

  &__image{
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    
    img,picture{
      transition: all .2s ease-in;
      height: 100%;
      width: 100%;
    }

    &::before{
      position: absolute;
      z-index: 2;
      content: '';
      background-image: url(../img/sprite/check.svg);
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: rgba(var(--bg-b),1.0);
      opacity: 0;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      transform: scale(0);
      transition: all .3s;
      top: -7px;
      left: -7px;
    }
  }

  input {
    &:checked + .custom-checkbox__image{
      &::before{
        transform: scale(1);
        opacity: 1;
      }
     }
  }

  &:hover{
    .custom-checkbox__image{
      img,picture{
        transform: scale(1.05);
      }
    }
  }
}

.main-checkbox {
  position: relative;

  input {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
  }

  span {
    cursor: pointer;
    position: relative;
    z-index: 2;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    user-select: none;
    color: rgba(var(--cl-b),1.0);
    width: 100%;
    font-family: var(--font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 400; 
    line-height: 100%;

    &::before {
      cursor: pointer;
      content: '';
      display: inline-block;
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      flex-grow: 0;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='800px' height='800px' viewBox='0 0 24 24'%3E%3C!--Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools--%3E%3Cg id='🔍-Product-Icons' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cg id='ic_fluent_checkbox_unchecked_24_regular' fill='%23212121' fill-rule='nonzero'%3E%3Cpath id='🎨Color' d='M5.75 3h12.5C19.7688 3 21 4.2312 21 5.75v12.5c0 1.5188-1.2312 2.75-2.75 2.75H5.75C4.2312 21 3 19.7688 3 18.25V5.75C3 4.2312 4.2312 3 5.75 3Zm0 1.5c-.6904 0-1.25.5596-1.25 1.25v12.5c0 .6904.5596 1.25 1.25 1.25h12.5c.6904 0 1.25-.5596 1.25-1.25V5.75c0-.6904-.5596-1.25-1.25-1.25H5.75Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  input {
    &:checked + span::before {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='800px' height='800px' viewBox='0 0 24 24'%3E%3C!--Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools--%3E%3Cg id='🔍-Product-Icons' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3E%3Cg id='ic_fluent_checkbox_checked_24_regular' fill='%23212121' fill-rule='nonzero'%3E%3Cpath id='🎨Color' d='M18.25 3C19.7688 3 21 4.2312 21 5.75v12.5c0 1.5188-1.2312 2.75-2.75 2.75H5.75C4.2312 21 3 19.7688 3 18.25V5.75C3 4.2312 4.2312 3 5.75 3h12.5Zm0 1.5H5.75c-.6904 0-1.25.5596-1.25 1.25v12.5c0 .6904.5596 1.25 1.25 1.25h12.5c.6904 0 1.25-.5596 1.25-1.25V5.75c0-.6904-.5596-1.25-1.25-1.25ZM10 14.4393l6.4697-6.4696a.75.75 0 0 1 1.1332.9765l-.0726.0841-7 7a.7502.7502 0 0 1-.9765.0726l-.0841-.0726-3-3a.75.75 0 0 1 .9765-1.1332l.0841.0726L10 14.4393l6.4697-6.4696L10 14.4393Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
}