:root {
  --card-top: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='266' height='279' fill='none'%3E%3Cg filter='url(%23filter0_f_7805_68)'%3E%3Ccircle cx='1' cy='0.5' r='128.5' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_7805_68' width='557' height='557' x='-277.5' y='-278' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_7805_68' stdDeviation='75'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
  --card-bottom: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='266' height='279' fill='none'%3E%3Cg filter='url(%23filter0_f_7805_69)'%3E%3Ccircle cx='266' cy='278.5' r='128.5' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_7805_69' width='557' height='557' x='-12.5' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_7805_69' stdDeviation='75'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
  --icon-btn: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='url(%23paint0_linear_2164_10362)' d='M40 11.4283 20 0 0 11.4283v17.1434L20 40l20-11.4283V11.4283Z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2164_10362' x1='40' x2='-7.93126' y1='-2.7025e-06' y2='24.4547' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237D72A9'/%3E%3Cstop offset='1' stop-color='%239A8BDA'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --icon-link: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='url(%23paint0_linear_1802_10927)' d='M20 8.5713 12 4 4 8.5713v6.8574L12 20l8-4.5713V8.5713Z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1802_10927' x1='20' x2='0.827495' y1='4' y2='13.7819' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237D72A9'/%3E%3Cstop offset='1' stop-color='%239A8BDA'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --hexagon-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='100' fill='none'%3E%3Cpath fill='url(%23paint0_linear_2224_20429)' d='M100.5 28.5707 50.5 0 .5 28.5707v42.8586L50.5 100l50-28.5707V28.5707Z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2224_20429' x1='100.5' x2='-19.3282' y1='-6.75624e-06' y2='61.1368' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237D72A9'/%3E%3Cstop offset='1' stop-color='%239A8BDA'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --offer-bg-mob: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='242' height='320' fill='none'%3E%3Cpath fill='url(%23paint0_linear_1837_13826)' d='M242 91.4264 121 0H0v320h121l121-91.426V91.4264Z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1837_13826' x1='242' x2='-76.111' y1='-2.162e-05' y2='122.741' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237D72A9'/%3E%3Cstop offset='1' stop-color='%239A8BDA'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --offer-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='288' height='320' fill='none'%3E%3Cpath fill='url(%23paint0_linear_1837_13251)' d='M288 91.4264 144 0H0v320h144l144-91.426V91.4264Z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1837_13251' x1='288' x2='-71.2013' y1='-2.162e-05' y2='164.939' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237D72A9'/%3E%3Cstop offset='1' stop-color='%239A8BDA'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --hexagon-white: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%23fff' stroke='url(%23paint0_linear_2224_34380)' stroke-width='4' d='m24 2.3035 22 12.5711v18.2508L24 45.6965 2 33.1254V14.8746L24 2.3035Z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2224_34380' x1='48' x2='-9.51752' y1='-3.243e-06' y2='29.3457' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237D72A9'/%3E%3Cstop offset='1' stop-color='%239A8BDA'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --grey-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='340' height='472' fill='none'%3E%3Cpath fill='url(%23paint0_linear_2172_14910)' d='M0 0h340v480H0z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2172_14910' x1='170' x2='170' y1='69' y2='480' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-opacity='0.2'/%3E%3Cstop offset='1' stop-opacity='0.9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --count-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46' fill='none'%3E%3Cpath fill='url(%23paint0_linear_1876_16694)' d='M13.1425 0 0 23l13.1425 23h19.715L46 23 32.8575 0h-19.715Z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1876_16694' x1='-5.1186e-06' x2='28.1229' y1='1.58565e-06' y2='55.121' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237D72A9'/%3E%3Cstop offset='1' stop-color='%239A8BDA'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --hexagon-big: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='262' height='202' fill='none'%3E%3Cpath fill='%23fff' stroke='url(%23paint0_linear_1876_16686)' stroke-width='4' d='m2.3617 101 62.171-99H197.467l62.171 99-62.171 99H64.5327l-62.171-99Z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1876_16686' x1='-1.77014e-05' x2='103.92' y1='6.96307e-06' y2='264.183' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237D72A9'/%3E%3Cstop offset='1' stop-color='%239A8BDA'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --hexagon-border: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath stroke='url(%23paint0_linear_1876_16701)' stroke-width='4' d='M2 14.8746 24 2.3035l22 12.5711v18.2508L24 45.6965 2 33.1254V14.8746Z'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1876_16701' x1='48' x2='-9.51752' y1='-3.243e-06' y2='29.3457' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237D72A9'/%3E%3Cstop offset='1' stop-color='%239A8BDA'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  --loop-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cg fill='%23fff' clip-path='url(%23clip0_1802_27987)'%3E%3Cpath d='M16.8584 14.7402a10.044 10.044 0 0 1-2.1099 2.1099l2.3577 2.3579 2.1099-2.1101-2.3577-2.3577ZM17.6789 8.8358C17.6789 3.9637 13.7152 0 8.8434 0 3.9713 0 .0077 3.9637.0077 8.8358c0 4.8718 3.9637 8.8355 8.8355 8.8355 4.8722 0 8.8356-3.9637 8.8356-8.8355Zm-4.9057.488h-3.197v3.1973a.562.562 0 0 1-.5619.5619.562.562 0 0 1-.5618-.5619V9.3239h-3.197a.5618.5618 0 1 1 0-1.1238h3.197v-3.197a.5618.5618 0 1 1 1.1237 0v3.197h3.197a.5618.5618 0 1 1 0 1.1238ZM20.0105 17.8926l-2.1101 2.1099 3.5727 3.5725c.5845.5709 1.5195.5654 2.0974-.0123.5777-.5779.5834-1.5129.0123-2.0976l-3.5723-3.5725Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1802_27987'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

